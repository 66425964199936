import useLocalStorageToPersistSelection from "hooks/use_local_storage_to_persist_selection"
import Filter from "components/dropdown_filter"

/**
 * @typedef UseFilterByCollectionItemHook
 * @property {function} Filter - React component for rendering the filter ui for the collection
 * @property {Object} filterProps - Props that should be spread into Filter component
 * @property {Object} selectedItem - the item from the collection that's currently selected
 * @property {number} selectedItemId - the ID of the selectedItem
 * @property {function} resetFilter - a funcction that resets the selectedItem and ID back to null
 * @property {string} setSelectedItem - a function to set the selected item manually from outside the Filter component
 */

/**
 * useFilterByCollectionItem - custom hook that facilitates storing and retrieving a user
 *  selection from a collection of items
 *
 * @param {Object} config
 * @param {string} config.localStorageKey - the key for storing this value in browser localStorage
 * @param {Object[]} config.collection - array of items from which a selection will be made
 * @param {?*} config.initialSelectedId - the ID of the initially selected item from the collection
 * @param {boolean} config.loading - flag to toggle loading UI
 * @param {function} config.onSelect - a function that gets called when a new selection is made
 * @param {boolean} config.overwriteWithInitialValue - set to true to overwrite current local storage value with initialSelectedId
 * Any additional props are passed through to Filter component
 *
 * @returns {UseFilterByCollectionItemHook}
 */
export default function useFilterByCollectionItem({
  localStorageKey,
  collection,
  initialSelectedId = null,
  onSelect,
  loading,
  overwriteWithInitialValue = false,
  ...additionalFilterProps
}) {
  const [
    selectedItem,
    selectedItemId,
    setSelectedItem,
  ] = useLocalStorageToPersistSelection({
    collection,
    localStorageKey,
    initialSelectedId,
    overwriteWithInitialValue,
  })

  function setSelectedItemAndExecuteCallback(item) {
    setSelectedItem(item)
    onSelect?.(item)
  }

  function handleFilteredItemChange(item) {
    setSelectedItemAndExecuteCallback(item)
  }

  function resetFilter() {
    setSelectedItemAndExecuteCallback(null)
  }

  const filterProps = {
    active: selectedItem,
    collection,
    onSelect: handleFilteredItemChange,
    spinner: loading,
    ...additionalFilterProps,
  }

  return {
    Filter,
    filterProps,
    selectedItem,
    selectedItemId,
    resetFilter,
    setSelectedItem: setSelectedItemAndExecuteCallback,
  }
}

import useApi from "hooks/use_api"

/**
 * @typedef UseFormCategoriesHook
 * @property {Object[]} formCategories - array of category objects
 * @property {function} refresh - function that can be called to invoke method again
 * @property {function} Pagination - React component for rendering pagination ui
 * @property {Object} paginationProps - Props that should be spread into Pagination component
 * @property {number} totalItemCount
 * @property {ApiResponse} response - response from the PCO API
 * @property {boolean} loading
 */

/**
 * useFormCategories - custom hook that fetches the form categories from PCO API
 *
 * @returns {UseFormCategoriesHook}
 */
export default function useFormCategories(overrideProps = {}) {
  const {
    response: { data: formCategories = [] },
    ...additionalProps
  } = useApi({
    method: "get",
    path: "/people/v2/form_categories",
    params: { order: "name" },
    perPage: 100,
    usePaging: false,
    ...overrideProps,
  })

  return {
    formCategories,
    ...additionalProps,
  }
}

import useLocalStorage from "hooks/use_local_storage"

/**
 * useLocalStorageToPersistSelection - custom hook that mimics useState behavior and persists value in localStorage
 *
 * @param {Object} config
 * @param {Object[]} config.collection
 * @param {?*} config.initialSelectedId - the ID of the item to be initially selected
 * @param {string} config.localStorageKey - the key for storing this value in browser localStorage
 * @param {boolean} config.overwriteWithInitialValue - set to true to overwrite current local storage value with initialSelectedId
 * @returns {Array}  array containing selectedItem, selectedItemId, and a function to set selected item (setSelectedItem)
 */
export default function useLocalStorageToPersistSelection({
  collection = [],
  initialSelectedId = null,
  localStorageKey,
  overwriteWithInitialValue = false,
}) {
  if (typeof localStorageKey !== "string") {
    throw new InvalidStorageKeyError(
      "You must provide a `localStorageKey` for `useLocalStorageToPersistSelection`."
    )
  }

  const [selectedItemId, setSelectedItemId, dropKey] = useLocalStorage(
    localStorageKey,
    initialSelectedId,
    overwriteWithInitialValue
  )

  const selectedItem = collection.find(item => item.id === selectedItemId)

  function setSelectedItem(item) {
    if (item === null || typeof item === "undefined") {
      dropKey()
      setSelectedItemId(undefined)
    } else if (typeof item?.id === "undefined") {
      throw new InvalidItemError("Selected Item must have an `id` attribute.")
    } else {
      setSelectedItemId(item.id)
    }
  }

  return [selectedItem, selectedItemId, setSelectedItem]
}

export class InvalidItemError extends Error {
  constructor(message) {
    super(message)
    this.name = "InvalidItemError"
  }
}

export class InvalidStorageKeyError extends Error {
  constructor(message) {
    super(message)
    this.name = "InvalidStorageKeyError"
  }
}

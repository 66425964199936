import useApi from "hooks/use_api"

/**
 * @typedef UseCampusesHook
 * @property {Object[]} campuses - array of campus objects
 * @property {function} refresh - Function that can be called to invoke method again
 * @property {function} Pagination - React component for rendering pagination ui
 * @property {Object} paginationProps - Props that should be spread into Pagination component
 * @property {number} totalItemCount
 * @property {boolean} loading
 */

/**
 * useCampuses - custom hook that fetches array of campuses from API
 * @returns {UseCampusesHook}
 */
export default function useCampuses(overrideProps = {}) {
  const {
    response: { data: campuses = [] },
    ...additionalProps
  } = useApi({
    method: "get",
    path: "/people/v2/campuses",
    params: { order: "name" },
    perPage: 100,
    usePaging: false,
    ...overrideProps,
  })

  return {
    campuses,
    ...additionalProps,
  }
}

import React from "react"
import {
  Button,
  Dropdown,
  StackView,
  Text,
} from "@planningcenter/tapestry-react"

/**
 * Filter: React component for rendering a dropdown from a collection of items
 * @param {Object} config
 * @param {Object} config.active - the currently selected item
 * @param {Object[]} config.collection - an aray of items to render in the dropdown list
 * @param {string[]} [config.manageLink=[]]
 * @param {?string} config.manageLink.manageLinkPath - the relative path to a view where these dropdown items can be managed
 * @param {?string} config.manageLink.manageLinkTitle - the title to display on the button that links to the manage view
 * @param {string} config.message - default label to display when no item is selected
 * @param {function} config.onSelect - function to be called when a new selection is made
 * @param {*} config.statusIndicator - React node(s) to display on the dropdown trigger indicating status such as success or error
 *
 * Any additional props get passed directly to the <Dropdown /> component from tapestry-react
 */
const Filter = ({
  active,
  collection,
  manageLink: [manageLinkPath, manageLinkTitle] = [],
  message,
  onSelect,
  statusIndicator,
  ...additionalProps
}) => (
  <Dropdown
    title={
      <StackView axis="horizontal" flex={1}>
        <Text truncate flex={1}>
          {active?.attributes?.name || message}
        </Text>
        {statusIndicator}
      </StackView>
    }
    variant="fill"
    theme="primary"
    paddingVertical={0.75}
    matchWidths={true}
    justifyContent="space-between"
    textAlign="left"
    {...additionalProps}
  >
    <Dropdown.Item onSelect={() => onSelect(undefined)} color="grey-8">
      {message}
    </Dropdown.Item>
    {collection.map(object => (
      <Dropdown.Item
        key={object.id}
        onSelect={() => onSelect(object)}
        color="grey-8"
      >
        <Text truncate>{object.attributes.name}</Text>
      </Dropdown.Item>
    ))}
    {manageLinkPath ? (
      <Dropdown.Item key={manageLinkPath} color="grey-8">
        <Button
          variant="outline"
          theme="primary"
          to={manageLinkPath}
          title={manageLinkTitle}
          flex={1}
        />
      </Dropdown.Item>
    ) : null}
  </Dropdown>
)

export default Filter
